<template>
  <!--begin::Mixed Widget 14-->
  <div class="card card-custom card-stretch gutter-b bg-light card-transparent">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-6">
      <div class="row">
        <div class="col-6 col-md-3">
          <stats-widget2
              :humidity="humidity"
              :collectYn="humidityYn"
          ></stats-widget2>
        </div>
        <div class="col-6 col-md-3">
          <stats-widget3
              :co2="co2"
              :collectYn="co2Yn"
          ></stats-widget3>
        </div>
        <div class="col-6 col-md-3">
          <stats-widget4
              :ph="ph"
              :collectYn="phYn"
          ></stats-widget4>
        </div>
        <div class="col-6 col-md-3">
          <stats-widget5
              :ec="ec"
              :collectYn="ecYn"
          ></stats-widget5>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 14-->
</template>

<script>
import StatsWidget2 from "@/view/content/widgets/dashboard/Widget2.vue";
import StatsWidget3 from "@/view/content/widgets/dashboard/Widget3.vue";
import StatsWidget4 from "@/view/content/widgets/dashboard/Widget4.vue";
import StatsWidget5 from "@/view/content/widgets/dashboard/Widget5.vue";

export default {
  name: "GroupWidget",
  props: {
    humidity: Number,
    co2: Number,
    ph: Number,
    ec: Number,
    farmConnectEAlarms: Array,
    onAlarmReleaseButtonClicked: Function,
    humidityYn: Boolean,
    co2Yn: Boolean,
    phYn: Boolean,
    ecYn: Boolean
  },
  components: {
    StatsWidget2,
    StatsWidget3,
    StatsWidget4,
    StatsWidget5
  },
  methods: {
    connectEAlarmByType(type) {
      const connectType = {
        ec: 'alarm20',
        ph: 'alarm40',
      }

      const alarmName = connectType[type]

      return this.farmConnectEAlarms.find(alarm => alarm[alarmName] === '1')
    }
  }
};
</script>

<template>
  <!--begin::Widget 1-->
  <div class="card card-custom card-stretch gutter-b bg-light card-transparent">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-6">
      <div class="chart d-flex flex-column align-items-center">
        <div class="chart-header">
          <span class="font-size-h5">{{i18n.title}}</span>
        </div>
        <div class="chart-body d-flex flex-column align-items-center" :class="{ blur: !collectYn}">
          <div class="chart-svg">
            <div class="chart-temperature d-flex pt-4">
              <div class="temp-axis d-flex flex-column justify-content-between ml-4 mr-2">
                <span class="font-size-sm font-weight-bold text-muted">40°</span>
                <span class="font-size-sm font-weight-bold text-muted">20°</span>
                <span class="font-size-sm font-weight-bold text-muted">0°</span>
              </div>
              <div class="temp">
                <div class="temp-value bg-danger" v-bind:style="{'height': temperatureHeight + '%'}"></div>
              </div>
            </div>
          </div>
          <div class="chart-value">
            <span v-if="collectYn" class="font-weight-bolder font-size-h2">{{ parseFloat(temperature.toFixed(1)) }}℃</span>
            <span v-else class="font-size-h2">{{ parseFloat(temperature.toFixed(1)) }}℃</span>
          </div>
        </div>
        <div v-if="!collectYn" class="d-flex flex-column align-items-center mt-30 overlay">
          <i class="flaticon-cancel icon-3x mb-3"></i>
          <div>데이터 미수집</div>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 1-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'Widget1',
  props: {
    temperature: Number,
    collectYn: Boolean
  },
  data() {
    return {
      chartOptions: {},
      i18n: {
        title: i18n.t('dashboard.temperature'),
      }
    };
  },
  computed: {
    temperatureHeight() {
      const temperatureHeight = this.temperature/40 * 100;
      if(temperatureHeight > 100) return 100;
      return temperatureHeight;
    }
  }
};
</script>

<template>
  <!--begin::Widget 8-->
  <div class="card card-custom card-stretch card-transparent gutter-b" v-bind:class="widgetClass">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div class="d-flex flex-column h-100 justify-content-between">
        <div class="d-flex align-items-start justify-content-between flex-grow-1 p-6">
          <span class="symbol symbol-50 symbol-white mr-2">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-xxl" v-bind:class="widgetIconColor">
                <inline-svg :src="widgetIcon" />
              </span>
            </span>
          </span>
          <div class="d-flex flex-column text-right">
            <span class="font-size-h4 font-weight-bold">{{ title }}</span>
          </div>
        </div>

        <monitoring-chart
            :title="title"
            :categories="categories"
            :series="chartSeries"
            :limitHigh="limitHigh"
            :limitLow="limitLow"
            :background-color="backgroundColor"
            :loading="loading"
            :class="{ blur: !collectYn}"
        ></monitoring-chart>
        <div v-if="!collectYn" class="d-flex flex-column align-items-center ml-40 mt-40 overlay">
          <i class="flaticon-cancel icon-3x mb-3"></i>
          <div>데이터 미수집</div>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 8 -->
</template>

<script>
import MonitoringChart from '@/components/MonitoringChart';

export default {
  name: "MonitoringChartWidget",
  components: {
    MonitoringChart
  },
  props: {
    widgetNumber: String,
    title: String,
    unit: String,
    categories: Array,
    chartSeries: Array,
    limitHigh: Number,
    limitLow: Number,
    loading: Boolean,
    collectYn: Boolean
  },
  data(){
    return {
      widgetColors : {
        '8': '#faf8ff',
        '9': '#f9fbff',
        '10': '#fff9f9',
        '11': '#f8fdff',
        '12': '#f9f9f9',
        '15': '#F9FCFF'
      },
      widgetIcons: {
        '8': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Temperature-empty.svg',
        '9': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Bucket.svg',
        '10': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Mask.svg',
        '11': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Eraser.svg',
        '12': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Fog.svg',
        '15': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Temperature-empty.svg'
      },
      widgetIconColors: {
        '8': 'svg-icon-primary',
        '9': 'svg-icon-info',
        '10': 'svg-icon-danger',
        '11': 'svg-icon-success',
        '12': 'svg-icon-dark',
        '15': 'svg-icon-info'
      }
    }
  },
  computed: {
    widgetClass(){
      return 'controller-widget' + this.widgetNumber;
    },
    backgroundColor(){
      return this.widgetColors[this.widgetNumber]
    },
    widgetIcon(){
      return this.widgetIcons[this.widgetNumber]
    },
    widgetIconColor(){
      return this.widgetIconColors[this.widgetNumber]
    }

  }
};
</script>

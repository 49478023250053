<template>
  <!--begin::Widget 1_2 -->
  <div class="card card-custom card-stretch gutter-b bg-light card-transparent">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-6">

      <div class="chart d-flex flex-column align-items-center">
        <div class="chart-header">
          <span class="font-size-h5">{{i18n.title}}</span>
        </div>
        <div class="chart-body d-flex flex-column align-items-center">
          <div class="chart-svg">
            <div class="chart-temperature d-flex pt-4">
              <div class="temp-axis d-flex flex-column justify-content-between ml-4 mr-2">
                <span class="font-size-sm font-weight-bold text-muted">40°</span>
                <span class="font-size-sm font-weight-bold text-muted">20°</span>
                <span class="font-size-sm font-weight-bold text-muted">0°</span>
              </div>
              <div class="temp">
                <div class="temp-value bg-info" v-bind:style="{'height': waterTempHeight + '%'}"></div>
              </div>
            </div>
          </div>
          <div class="chart-value">
            <span class="font-weight-bolder font-size-h2">{{ waterTempValue }}℃</span>
          </div>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 1_2 -->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'Widget1_2',
  props: {
    waterTemperature: Number,
  },
  data() {
    return {
      i18n: {
        title: i18n.t('dashboard.waterTemperature'),
      }
    }
  },
  computed: {
    waterTempValue() {
      return parseFloat(this.waterTemperature.toFixed(1))
    },
    waterTempHeight() {
      const temperatureHeight = this.waterTemperature/40 * 100;
      if(temperatureHeight > 100) return 100;
      return temperatureHeight;
    }
  }

};
</script>

<template>
  <!--begin::Widget 5-->
  <div class="chart d-flex flex-column align-items-center">
    <div class="chart-header">
      <span class="font-size-h5">{{ title }}</span>
    </div>
    <div class="chart-body d-flex flex-column align-items-center" :class="{ blur: !collectYn }">
      <div class="chart-svg">
        <div class="chart-icon">
          <span class="svg-icon svg-icon-primary">
            <inline-svg
              src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Mask.svg"
            ></inline-svg>
          </span>

        </div>
        <apexchart
            :options="chartOptions"
            :series="series"
            type="radialBar"
          ></apexchart>
      </div>
      <div class="chart-value">
        <span v-if="collectYn" class="font-weight-bolder font-size-h2">{{ ecStr }}</span>
        <span v-else class="font-size-h2">{{ ecStr }}</span>
      </div>
    </div>
    <div v-if="!collectYn" class="d-flex flex-column align-items-center mt-30 overlay">
      <i class="flaticon-cancel icon-3x mb-3"></i>
      <div>데이터 미수집</div>
    </div>
  </div>
  <!--end::Widget 5-->
</template>

<script>
import { mapGetters } from "vuex";
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Widget5",
  props: {
    ec: Number,
    collectYn: Boolean
  },
  data() {
    return {
      title: i18n.t('dashboard.EC'),
      chartOptions: {}
    };
  },
  // methods: {
  //   onECAlarmReleaseButtonClicked(seq) {
  //     this.isAlarmReleaseButtonClicked =  true
  //     this.$store.dispatch(RELEASE_CONNECT_ALARM, { seq: seq, apply: 1 }).then(() => {
  //       // const activatedAlarms = this.farmConnectEAlarms.filter(alarm => alarm.systemId !== connectSystemId)
  //       // this.farmConnectEAlarms = activatedAlarms
  //     }).catch(error => console.log('Error: ', error));
  //   }
  // },
  computed: {
    ...mapGetters(["layoutConfig"]),
    series(){
      return [this.ec];
    },
    ecStr(){
      return (this.ec / 10 || 0).toFixed(2)
    }
  },
  mounted() {
    this.chartOptions = {
      chart: {
        zoomType: 'x,y',
        height: 200,
        type: "radialBar",
        offsetY: 20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: this.layoutConfig("colors.dark"),
              fontSize: "25px",
              fontWeight: "700",
              offsetY: 100,
              show: false
            }
          },
          track: {
            background: this.layoutConfig("colors.theme.light.info"),
            strokeWidth: "100%"
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.base.info")],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };
  }
};
</script>

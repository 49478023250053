<template>
  <!--begin::Widget 7-->
  <div class="card card-custom card-transparent gutter-b bg-light p-6">
    <!--begin::Body-->
    <div class="card-body d-flex justify-content-between p-0">
      <div class="d-flex flex-column justify-content-between">
        <!--begin::Symbol-->
        <div class="symbol symbol-40 mr-5 symbol-white">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-lg svg-icon-dark">
              <!--begin::Svg Icon-->
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Electric/Fan.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </span>
        </div>
        <!--end::Symbol-->
        <span class="text font-weight-bolder font-size-h5">FAN</span>
      </div>
      <!--begin::Switch-->
      <div class="switch d-flex flex-column" v-bind:class="{'is-active': fanStat, 'no-data': fanStat === null}">
        <div class="switch-label d-flex justify-content-between font-size-sm font-weight-bold">
          <span class="text" v-bind:class="{'text-muted': fanStat || fanStat == null, 'text-danger': !(fanStat == null) && !fanStat}">OFF</span>
          <span class="text" v-bind:class="{'text-muted': (!(fanStat == null) && !fanStat) || fanStat == null, 'text-success': fanStat}">ON</span>
        </div>
        <div class="switch-content">
          <div class="circle"
             v-bind:class="{'bg-success': fanStat, 'bg-danger': !(fanStat == null) && !fanStat, 'bg-secondary': fanStat == null}"
          >
            <span class="edge"
               v-bind:class="{'bg-success': fanStat, 'bg-danger': !(fanStat == null) && !fanStat, 'bg-secondary': fanStat == null}"
            ></span>
          </div>
        </div>
      </div>
      <!--end::Switch-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 7-->
</template>

<script>

export default {
  name: "Widget7",
  props: {
    fan: Number
  },
  computed: {
    fanStat() {
      return this.fan === -1 ? null : this.fan === 1 ? true : false
    }
  }
};
</script>

<template>
  <div style="height: 100%; min-height: 230px">
    <highcharts v-if="series && series.length" :options="chartOptions" v-bind:style="{height: '100%'}"></highcharts>
    <div v-else class="text-center" v-bind:style="{ height: '100%'}">
      <span v-if="loading" class="spinner farm-chart-spinner" style="top: 70px;"></span>
      <span v-else style="top: 70px;"><br><br><br>{{ i18n.noData }}</span>
    </div>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import i18n from '@/core/plugins/vue-i18n.js'
import {getHighchartYaxisMinMax, timestampToDate, getRefinedHighchartSeriesData} from '@/core/services/funcs.js'

export default {
  name: 'MonitoringChart',
  components: {
    highcharts: Chart
  },
  props: {
    title: String,
    categories: Array,
    series: Array,
    limitHigh: Number,
    limitLow: Number,
    backgroundColor: String,
    loading: Boolean
  },
  data (){
    return {
      i18n: {
        noData: i18n.t('farm.timeseries.noData'),
        zeroVlaueDescription: i18n.t('farm.timeseries.zeroVlaueDescription')
      }
}
  },
  computed: {
    chartOptions(){
      const nowDate = new Date(this.categories[0] || 0)
      nowDate.setHours(nowDate.getHours() + 9)
      const yAxisMinMax = getHighchartYaxisMinMax(this.series, this.limitLow, this.limitHigh)
      const zeroVlaueDescription = this.i18n.zeroVlaueDescription

      const refinedSeriesData = getRefinedHighchartSeriesData(this.series)

      return {
        chart: {
          zoomType: 'x,y',
          // backgroundColor: '#faf8ff'
          backgroundColor: this.backgroundColor || '#ffff'
        },
        plotOptions: {
          series: {
            turboThreshold: 10000
          }
        },
        rangeSelector: {
          selected: 1
        },
        title: {
          // text: '<span class="font-weight-bold" style="color: #7e8299; font-size: 13px">' + this.title + '</span>'
          text: '<span class="font-weight-bold"></span>'
        },
        yAxis: {
          title: {
            text: ''
          },
          plotLines: [{
            label: {
              enabled: false,
              x: this.limitHigh
            },
            color: 'orange',
            width: 2,
            value: this.limitHigh,
          },{
            label: {
              enabled: false,
              x: this.limitLow
            },
            color: 'orange',
            width: 2,
            value: this.limitLow,
          }],
          min: yAxisMinMax.min - (yAxisMinMax.min+yAxisMinMax.max)/20,
          max: yAxisMinMax.max + (yAxisMinMax.min+yAxisMinMax.max)/20,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%H:%M}'
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            if (this.y === 0) {
              return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span> <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span> <br> <span style="font-size: 10px;">${zeroVlaueDescription}</span>`
            } else {
              return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span> <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span>`
            }
          }
        },
        series: [{
          name: this.title,
          showInLegend: false,
          tooltip: {
            valueDecimals: 2
          },
          data: refinedSeriesData,
          // pointStart: nowDate.getTime(),
          // pointInterval: (1000 * 60),
          zones: [{
            value: this.limitLow,
            color:'red'
          },{
            value: this.limitHigh,
            color:'skyblue'
          },{
            color:'red'
          }]
        }]
      }
    }
  }
};
</script>
<template>
  <!--begin::Widget 6-->
  <div class="card card-custom card-transparent gutter-b bg-light p-6">
    <!--begin::Body-->
    <div class="card-body d-flex justify-content-between p-0">
      <div class="d-flex flex-column justify-content-between">
        <!--begin::Symbol-->
        <div class="symbol symbol-40 mr-5 symbol-white">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-lg svg-icon-dark">
              <!--begin::Svg Icon-->
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Media/Rec.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </span>
        </div>
        <!--end::Symbol-->
        <span class="text font-weight-bolder font-size-h5">LIGHT</span>
      </div>
      <!--begin::Switch-->
      <div class="switch d-flex flex-column" v-bind:class="{'is-active': ledStat, 'no-data': ledStat === null}">
        <div class="switch-label d-flex justify-content-between font-size-sm font-weight-bold">
          <span class="text" v-bind:class="{'text-muted': ledStat || ledStat == null, 'text-danger': !(ledStat == null) && !ledStat}">OFF</span>
          <span class="text" v-bind:class="{'text-muted': (!(ledStat == null) && !ledStat) || ledStat == null, 'text-success': ledStat}">ON</span>
        </div>
        <div class="switch-content">
          <div class="circle"
             v-bind:class="{'bg-success': ledStat, 'bg-danger': !(ledStat == null) && !ledStat, 'bg-secondary': ledStat == null}"
          >
            <span class="edge"
               v-bind:class="{'bg-success': ledStat, 'bg-danger': !(ledStat == null) && !ledStat, 'bg-secondary': ledStat == null}"
            ></span>
          </div>
        </div>
      </div>
      <!--end::Switch-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 6-->
</template>

<script>

export default {
  name: "Widget6",
  props: {
    led: Number
  },
  computed: {
    ledStat() {
      return this.led === -1 ? null : this.led === 1 ? true : false
    }
  }
};
</script>
